import { useForm } from "react-hook-form";
import registerImg from "../../images/register-page/graphic1.svg";
import { useDispatch, useSelector } from "react-redux";
import { registerFunc } from "../../store/slices/register-action/RegisterSlicer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleRegisterFunc } from "../../store/slices/google-auth/GoogleRegisterSlicer";
import Swal from "sweetalert2";
import { jwtDecode } from "jwt-decode";
import { getProfileData } from "../../store/slices/users/profileSlicer";
import { LogedIn } from "../../store/slices/LogedinStatusSlicer";

function RegisterPage() {
    const { t } = useTranslation();
    const {data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
    const {loading:getUiSettingsLoading , data:settingsData} = useSelector(
        state => state.getUiSettingsSlicer
    )
    const { register, handleSubmit, formState: { errors } } = useForm();
    const registerApi = `${process.env.REACT_APP_PUBLIC_API}/api/register`;
    const dispatch = useDispatch();
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [showPass, setShowPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const { loading: registerLoading } = useSelector(state => state.registerSlicer);
    const navigate = useNavigate();
    const registerFunction = (data) => {
        if (data) {
            if (pass.length < 8) {
                toast.warning(t("Password must be longer than 8 characters"), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                if (pass !== confirmPass) {
                    toast.warning(t("Passwords do not match"), {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                } else {
                    try {
                        dispatch(registerFunc({ api: registerApi, data: data }))
                            .then(result => {
                                dispatch(LogedIn())
                                if (result.payload.success === true) {
                                    if(result?.payload?.data?.student?.email_verified_at === null) {
                                        navigate("/verify-email");
                                    }else {
                                        navigate("/profile");
                                    }
                                }
                            });
                    } catch (error) {
                        toast.error(error, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                }
            }
        }
    };
    const onFailure = () => {
        console.log("Login Failed");
    };
    const onSuccess = (res) => {
    const decoded = jwtDecode(res.credential);
    const userCredintianalswithGoogle = {
        name: decoded.name,
        email: decoded.email,
        googleId: decoded.sub,
    };
    dispatch(GoogleRegisterFunc(userCredintianalswithGoogle)).then(
        (result) => {
        console.log(result.payload)
        if (result.payload.status_code === 200 && result.payload?.success === true) {
            setTimeout(() => window.location.reload() , 10)
            // // console.log(window.localStorage.getItem("token"))
            // // console.log(pathname)
            navigate("/");
            // dispatch(LogedIn())
        }
        }
    );
    };
    return (
        <>
            <div className="register-page relative pt-[100px] pb-[60px]">
                <div className="page-container relative w-[90%] mx-auto flex justify-between items-center row">
                    <div className="relative register-page-image col-sm-12 col-md-6">
                        <div className="relative col-sm-12 h-[400px] image-content ">
                            <img src={registerImg} alt="img" className="absolute top-0 left-0 w-full h-full" />
                        </div>
                    </div>
                    <div className="form-content col-sm-12 col-md-6">
                        <form
                            className="relative w-full col-sm-12"
                            id="register-form"
                            onSubmit={handleSubmit((data) => {
                                registerFunction(data);
                            })}
                        >
                            <div className="form-header relative flex flex-col justify-center items-center col-sm-12 mb-2 py-2">
                                <h2 className="font-bold mb-2 w-full md:text-right dark:text-lightText">
                                    {t("Get more done with the education platform")}
                                </h2>
                                <h3 className="w-full md:text-right dark:text-lightText">
                                    {t("Access the most powerful tool in the entire education and learning industry")}
                                </h3>
                            </div>
                            <div className="row mt-2">
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                    {errors.name?.message && (
                                        <p className="text-red-600">
                                            {errors.name?.message}
                                        </p>
                                    )}
                                    <input
                                        {...register("name", {
                                            required: t("Name is required!")
                                        })}
                                        type="text"
                                        className="form-control shadow-none dark:border-lightText dark:bg-darkBg dark:placeholder:text-lightText dark:border dark:text-lightText"
                                        placeholder={t("Name")}
                                        onChange={(e) => {
                                            // setName(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                    {errors.email?.message && (
                                        <p className="text-red-600">
                                            {errors.email?.message}
                                        </p>
                                    )}
                                    <input
                                        {...register("email", {
                                            required: t("Email is required!")
                                        })}
                                        type="text"
                                        className="form-control shadow-none dark:border-lightText dark:bg-darkBg dark:placeholder:text-lightText dark:border dark:text-lightText"
                                        placeholder={t("Email")}
                                        onChange={(e) => {
                                            // setName(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mb-3 col-sm-12">
                                    {errors.phone?.message && (
                                        <p className="text-red-600">
                                            {errors.phone?.message}
                                        </p>
                                    )}
                                    <input
                                        {...register("phone", {
                                            required: t("Phone number is required")
                                        })}
                                        type="text"
                                        className="form-control shadow-none dark:border-lightText dark:bg-darkBg dark:placeholder:text-lightText dark:border dark:text-lightText"
                                        placeholder={t("Phone number")}
                                        onChange={(e) => {
                                            // setName(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                            {
                                settingsData?.enable_parent_phone === "enable"
                                ?
                                <div className="row">
                                    <div className="form-group mb-3 col-sm-12">
                                        {errors.parent_phone?.message && (
                                            <p className="text-red-600">
                                                {errors.parent_phone?.message}
                                            </p>
                                        )}
                                        <input
                                            {...register("parent_phone", {
                                                required: settingsData?.enable_parent_phone === "enable" ? t("Parent Phone number is required") : false,
                                            })}
                                            type="text"
                                            className="form-control shadow-none dark:border-lightText dark:bg-darkBg dark:placeholder:text-lightText dark:border dark:text-lightText"
                                            placeholder={t("Parent Phone number")}
                                            onChange={(e) => {
                                                // setName(e.target.value)
                                            }}
                                        />
                                    </div>
                                </div>
                                :
                                ""
                            }
                            <div className="row">
                                <div className="form-group relative mb-3 col-sm-12 col-md-6">
                                    {errors.password?.message && (
                                        <p className="text-red-600">
                                            {errors.password?.message}
                                        </p>
                                    )}
                                    <div className="relative">
                                        <input
                                            {...register("password", {
                                                required: t("Password is required")
                                            })}
                                            type={showPass ? "text" : "password"}
                                            className="form-control shadow-none dark:border-lightText dark:bg-darkBg dark:placeholder:text-lightText dark:border dark:text-lightText"
                                            placeholder={t("Password")}
                                            onChange={(e) => {
                                                setPass(e.target.value);
                                            }}
                                            value={pass}
                                        />
                                        <i
                                            className={`fa-solid fa-eye absolute top-[50%] translate-y-[-50%] ${languageData?.defaultLang?.slug === "ar" ? "left-[10px]" : "right-[10px]"} ${pass !== "" ? "opacity-100" : "opacity-10"} transition-all duration-300 cursor-pointer dark:text-lightText`}
                                            onClick={() => setShowPass(!showPass)}
                                        ></i>
                                    </div>
                                </div>
                                <div className="form-group relative mb-3 col-sm-12 col-md-6">
                                    {errors.password_confirmation?.message && (
                                        <p className="text-red-600">
                                            {errors.password_confirmation?.message}
                                        </p>
                                    )}
                                    <div className="relative">
                                        <input
                                            {...register("password_confirmation", {
                                                required: t("Password confirmation is required!")
                                            })}
                                            type={showConfirmPass ? "text" : "password"}
                                            className="form-control shadow-none dark:border-lightText dark:bg-darkBg dark:placeholder:text-lightText dark:border dark:text-lightText"
                                            placeholder={t("Confirm Password")}
                                            onChange={(e) => {
                                                setConfirmPass(e.target.value);
                                            }}
                                            value={confirmPass}
                                        />
                                        <i
                                            className={`fa-solid fa-eye absolute top-[50%] translate-y-[-50%] ${languageData?.defaultLang?.slug === "ar" ? "left-[10px]" : "right-[10px]"} ${confirmPass !== "" ? "opacity-100" : "opacity-10"} transition-all duration-300 cursor-pointer dark:text-lightText`}
                                            onClick={() => setShowConfirmPass(!showConfirmPass)}
                                        ></i>
                                    </div>
                                </div>
                            </div>
                            <div className="inputs relative flex items-center my-2">
                                <button
                                    type="submit"
                                    className={`relative flex justify-center items-center bg-primary text-white p-2 ${registerLoading ? " pointer-events-none opacity-50" : ""}`}
                                >
                                    {
                                        registerLoading
                                            ? t("Loading...")
                                            : t("Create Account")
                                    }
                                </button>
                                <Link to="/login" className="relative flex justify-center items-center mr-2 p-2 dark:text-lightText">
                                    {t("Login")}
                                </Link>
                                <Link to="/" className="relative flex justify-center items-center mr-2 p-2 dark:text-lightText">
                                    {t("Home")}
                                </Link>
                            </div>
                            {
                                settingsData?.enable_google_auth === "true"
                                ?
                                <>
                                    <div className="relative w-full flex justify-between items-center my-3">
                                        <span className="relative w-[30%] h-[1px] bg-slate-400 dark:bg-white"></span>
                                        <span className="dark:text-lightText">
                                            {t("Sign Up With")}
                                        </span>
                                        <span className="relative w-[30%] h-[1px] bg-slate-400 dark:bg-white"></span>
                                    </div>
                                    <GoogleLogin
                                        onSuccess={onSuccess}
                                        onError={onFailure}
                                        theme="outline"
                                    />
                                </>
                                :
                                ""
                            }
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RegisterPage;
