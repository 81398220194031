import { useContext, useEffect, useState } from "react";
import ContextApi from "../../../store/slices/Context";
import { Link } from "react-router-dom";
import aboutImg from "../../../images/modern-3d-illustration-education-concept_145666-1728-removebg-preview.png";
import emailIcon from "../../../images/about/mail.png";
import adressIcon from "../../../images/about/adress.png";
import phoneIcon from "../../../images/about/phone-call.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function AboutSection() {
  const { t } = useTranslation();
  const contextApi = useContext(ContextApi);
  const { contact_email } = contextApi;
  const { contact_phone } = contextApi;
  const { contact_address } = contextApi;
  const { about_content } = contextApi;
  const {loading:getUiSettingsLoading , data} = useSelector(
    state => state.getUiSettingsSlicer
  )
  const {loading:getLanguagesLoading , data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
  const [img , setImage] = useState(null)
  useEffect(() => {
    data && setImage(data?.site_banner_about);
  },[data])
  return (
    <>
      <section className="about-two dark:bg-darkBg">
        <div className="about-container w-[90%] mx-auto row">
          <div className="image-side relative col-sm-12 col-md-6 flex justify-center items-center">
            <div className="image-div relative w-full h-[400px]">
              <img src={img ? `${process.env.REACT_APP_PUBLIC_API}/storage/${img}` : aboutImg} alt="img" className="absolute top-0 left-0 w-full h-full" />
            </div>
          </div>
          <div className="text-side relative col-sm-12 col-md-6">
            <div className="about-text relative flex flex-col">
              <h2 className="transition-all duration-500 hover:tracking-[2px] text-[20px] md:text-[30px] lg:text-[40px] font-bold text-slate-700 dark:text-lightText mb-2">
                {t("Who Are We?")}
              </h2>
              <div dangerouslySetInnerHTML={{__html : about_content?.[languageData?.defaultLang?.slug]}} />
            </div>
          </div>
        </div>
        <div className="about-container w-[90%] mx-auto row gap-2 justify-between py-4">
          <div className="emails-contact relative flex flex-col justify-center items-center p-3 w-full md:w-[270px] bg-slate-100 dark:bg-darkBg dark:shadow-md dark:shadow-black hover:bg-black transition-all duration-500 hover:translate-y-[-3px]">
            <img src={emailIcon} alt="img" className="my-2 w-[50px]" />
            <Link to={`mailto:${contact_email}`} className="mb-1 dark:text-lightText">
              {contact_email}
            </Link>
          </div>
          <div className="emails-contact relative flex flex-col justify-center items-center p-3 w-full md:w-[270px] bg-slate-100 dark:bg-darkBg dark:shadow-md dark:shadow-black hover:bg-black transition-all duration-500 hover:translate-y-[-3px]">
            <img src={phoneIcon} alt="img" className="my-2 w-[50px]" />
            <Link to={`https://wa.me/${contact_phone}`} target="_blank" className="mb-1 dark:text-lightText">
              {contact_phone}
            </Link>
          </div>
          <div className="emails-contact relative flex flex-col justify-center items-center p-3 w-full md:w-[270px] bg-slate-100 dark:bg-darkBg dark:shadow-md dark:shadow-black hover:bg-black transition-all duration-500 hover:translate-y-[-3px]">
            <img src={adressIcon} alt="img" className="my-2 w-[50px]" />
            <Link className="mb-1 dark:text-lightText">
              {contact_address}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
}
