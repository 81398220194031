import { Link } from "react-router-dom";
import logocustom from "../../images/resources/logo-custom.png";
import footer_bg from "../../images/backgrounds/footer-bg.png";
import platformLogoImg from "../../images/1.png";
import { useContext, useEffect, useState } from "react";
import ContextApi from "../../store/slices/Context";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

export default function Footer() {
  const contextApi = useContext(ContextApi);
  const { t } = useTranslation();
  const platformLogo = contextApi?.site_logo;
  const contactAddress = contextApi?.contact_address;
  const contactEmail = contextApi?.contact_email;
  const contactPhone = contextApi?.contact_phone;
  const facebookLink = contextApi?.facebook_link;
  const instagramLink = contextApi?.instagram_link;
  const linkedinLink = contextApi?.linkedin_link;
  const twitterLink = contextApi?.twitter_link;
  const youtubeLink = contextApi?.youtube_link;
  const {loading:getCategoriesLoading , data:categoriesData} = useSelector(
    state => state.categoriesSlicer
  )
  const {loading:getLanguagesLoading , data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
  const [categoriesList , setCategoriesList] = useState([])
  useEffect(() => {
    if(categoriesData) {
      setCategoriesList(categoriesData?.data?.categories)
    }
  },[categoriesData])
  return (
    <>
      <footer className="footer-one">
        <div className="footer-one__bg bg-[#041739]">
        </div>
        <div className="footer-one__top">
          {/* <div className="container-fluid"> */}
          <div className="container">
            <div className="row">
              {/*Start Footer Widget Column*/}
              <div className="col-sm-12 col-md-3 wow animated fadeInUp" data-wow-delay="0.1s">
                <div className="footer-widget__column footer-widget__about">
                  <div className="footer-widget__about-logo">
                    <Link to="/">
                      <img loading="lazy" src={`${process.env.REACT_APP_PUBLIC_API}/storage/${platformLogo}`} alt={t("Platform Logo")} className="max-w-[100px]"/>
                    </Link>
                  </div>
                </div>
                <div className="relative my-2">
                  <div dangerouslySetInnerHTML={{__html : contextApi?.footer_content?.[languageData?.defaultLang?.slug]}} />
                </div>
                {/* <div className="col-xl-3 col-lg-6 col-md-6 wow animated fadeInUp" data-wow-delay="0.9s"> */}
                  {/* <div className="footer-widget__column footer-widget__social-links"> */}
                    <ul className="footer-widget__social-links-list list-unstyled clearfix flex mt-4">
                      <li className="mx-[8px]"><Link className="bg-[#213e97] before:hidden p-0" to={facebookLink}><i className="fab fa-facebook m-0" /></Link></li>
                      <li className="mx-[8px]"><Link className="bg-[#1da1f2] before:hidden p-0" to={twitterLink}><i className="fab fa-twitter m-0" /></Link></li>
                      <li className="mx-[8px]"><Link className="bg-[#c13584] before:hidden p-0" to={instagramLink}><i className="fab fa-instagram m-0" /></Link></li>
                      <li className="mx-[8px]"><Link className="bg-[#ff0000] before:hidden p-0" to={youtubeLink}><i className="fab fa-youtube m-0" /></Link></li>
                    </ul>
                  {/* </div> */}
                {/* </div> */}
              </div>
              <div className=" col-sm-12 col-md-3 wow animated fadeInUp" data-wow-delay="0.3s">
                <div className="footer-widget__column footer-widget__courses">
                  <h3 className="footer-widget__title">{t("Categories")}</h3>
                  <ul className="footer-widget__courses-list list-unstyled">
                    {
                      getCategoriesLoading
                      ?
                      t("loading")
                      :
                      (
                        categoriesList?.slice(0,6).map((cat , index) => (
                          <li><Link to={"/courses"} key={index}>{cat?.name}</Link></li>
                        ))
                      )
                    }
                  </ul>
                </div>
              </div>
              <div className=" col-sm-12 col-md-3 wow animated fadeInUp" data-wow-delay="0.5s">
                <div className="footer-widget__column footer-widget__links">
                  <h3 className="footer-widget__title">{t("Quick Links")}</h3>
                  <ul className="footer-widget__links-list list-unstyled">
                    <li><Link to={"/about"}>{t("About Us")}</Link></li>
                    <li><Link to={"/about"}>{t("Quick Glance")}</Link></li>
                    <li><Link to={"/about"}>{t("Teachers")}</Link></li>
                    <li><Link to={"/contact"}>{t("Join Us")}</Link></li>
                  </ul>
                </div>
              </div>
              <div className=" col-sm-12 col-md-3 wow animated fadeInUp" data-wow-delay="0.7s">
                <div className="footer-widget__column footer-widget__contact">
                  <h3 className="footer-widget__title">{t("Contact Us")}</h3>
                  <p className="text"><GpsFixedIcon/> {contactAddress}</p>
                  <p><a href={`mailto:${contactEmail}`}><AlternateEmailIcon/> {contactEmail}</a></p>
                  <p className="phone">
                    <Link to={`https://wa.me/${contactPhone}`} target="_blank">
                      <PhonelinkIcon /> {contactPhone}
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Start Footer One Bottom*/}
        <div className="footer-one__bottom">
          {/* <div className="container-fluid"> */}
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-one__bottom-inner">
                  <div className="footer-one__bottom-text text-center">
                    <p>© {t("All rights reserved by")} 
                    <Link to=""> &nbsp;  &nbsp; TOPGROWTH </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
};
