import { useTranslation } from "react-i18next";
import "./overlay-loading.css"
function OverLayLoading({loading , loadingtext}) { 
    const {t} = useTranslation();
    return (
        <>
        <div className={`overlay-parent fixed w-full h-full top-0 left-0 transition-all duration-500 z-[999999999] flex flex-col justify-center items-center bg-[#d3d3d326] backdrop-blur-[3px] ${loading ? "active" : ""}`}>
            <div class="spinner"></div>
            <p className="my-2 text-[#272ac5]">{loadingtext}</p>
        </div>
        </>
    )
}
export default OverLayLoading;