import { createSlice } from "@reduxjs/toolkit";

const logedinStatusSlicer = createSlice({
  name: "login/status",
  initialState: {
    // status: window.localStorage.getItem("token") ? true : false,
    isLogin: null,
  },
  reducers: {
    setLogedInStatus: (state) => {
      const localToken = localStorage.getItem("token");
      if (localToken) {
        state.isLogin = true;
      } else {
        state.isLogin = false;
      }
    },
    // changeLogedInStatus: (state , action) => {
    //     state.status = action.payload;
    // },
    LogedIn(state){  
      state.isLogin = true;
    },
    LogedOut(state){
        state.isLogin = false;
        localStorage.removeItem("userData");
    },
  },
});

export const { setLogedInStatus , changeLogedInStatus , LogedIn , LogedOut} = logedinStatusSlicer.actions;
export default logedinStatusSlicer.reducer;
