import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import ContextApi from "../../store/slices/Context";
import platfotmImg from "../../images/1.png";
import { Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// const SingleDrop = ({catId, catName, catAllchildren, closeNav}) => {
//     const [open, setOpen] = useState(false);
//     const navigate = useNavigate();
//     const handleNavigate = (id) => {
//         id ? navigate(`/courses?category=${id}`) : navigate(`/courses`);
//         closeNav && closeNav();
//     }
//     return (
//         <>
//             <Dropdown show={open}>
//                 <Dropdown.Toggle variant="success" id="dropdown-basic" className="bg-[#157347] mb-2 col-sm-12 after:hidden flex items-center justify-center">
//                     <p className="text-sm" onClick={() => handleNavigate(catId)}>{catName}</p>
//                     <i className={`fa-solid fa-angle-left mr-2 transition-all duration-500 ${open ? "rotate-[90deg]" : "rotate-[-90deg]"} bg-[#114f32] p-1 rounded-md flex justify-center items-center`}
//                        onClick={() => setOpen(!open)}
//                     ></i>
//                 </Dropdown.Toggle>
//                 <Dropdown.Menu>
//                     <LoopAllChildren categories={catAllchildren}/>
//                 </Dropdown.Menu>
//             </Dropdown>
//         </>
//     )
// }

// const LoopAllChildren = ({categories, closeNav}) => {
//     return (
//         <>
//             {
//                 categories?.length >= 1 &&
//                 categories.map((cat, index) => (
//                     cat.all_children?.length >= 1
//                         ?
//                         <SingleDrop catId={cat?.id} catName={cat?.name} catAllchildren={cat.all_children} closeNav={() => closeNav()}/>
//                         :
//                         <Dropdown.Toggle variant="success" id="dropdown-basic" className="bg-[#157347] mb-2 col-sm-12 after:hidden">
//                             {cat.name}
//                         </Dropdown.Toggle>
//                 ))
//             }
//         </>
//     )
// }

// const CategoriesDropDown = ({categories, closeNav}) => {
//     const {t} = useTranslation();
//     const navigate = useNavigate();
//     return (
//         <>
//             <Dropdown className="p-3">
//                 <Dropdown.Toggle variant="success" id="dropdown-basic">
//                     {t("Categories")}
//                 </Dropdown.Toggle>
//                 <Dropdown.Menu>
//                     <Dropdown.Toggle variant="success" id="dropdown-basic" className="bg-[#157347] mb-2 col-sm-12 after:hidden"
//                         onClick={() => {
//                             navigate("/courses");
//                             closeNav && closeNav();
//                         }}
//                     >
//                         {t("All")}
//                     </Dropdown.Toggle>
//                     {/* <LoopAllChildren categories={categories} closeNav={() => closeNav()}/> */}
//                 </Dropdown.Menu>
//             </Dropdown>
//         </>
//     )
// }


const CategoriesList = ({categories , handleCloseNav}) => {
    const {t} = useTranslation();
    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                {t("Categories")}
            </Dropdown.Toggle>
    
            <Dropdown.Menu>
                <ul>
                    {
                        categories?.length >= 1
                        ?
                        <>
                            {/* <li className="mb-2">
                                <Link to="/courses" onClick={() => handleCloseNav()}>{t("All")}</Link>
                            </li> */}
                            {
                                categories.map((cat , index) => (
                                    <li key={index} className="mb-2">
                                        <Link to={`/courses?category=${cat?.id}`} onClick={() => handleCloseNav()}>{cat?.name}</Link>
                                    </li>
                                ))
                            }
                        </>
                        :
                        <li className="my-2">
                            <Link to="#" onClick={() => handleCloseNav()}>{t("No Categories")}</Link>
                        </li>
                    }
                </ul>
                
            </Dropdown.Menu>
        </Dropdown>
    )
}


function MobileNavBar({showMobileNav, hideShowMobileNav, categories}) {
    const {t} = useTranslation();
    const location = useLocation();
    const nameLocation = location.pathname;
    const contextApi = useContext(ContextApi);
    const platformLogo = contextApi?.site_logo;
    const contactEmail = contextApi?.contact_email;
    const {loading: getCategoriesLoading, data: categoriesData} = useSelector(
        state => state.categoriesSlicer
    );

    return (
        <>
            <div className={`h-[100vh] z-[999999] bg-slate-800 flex flex-col items-center justify-center fixed ${showMobileNav ? 'right-0' : "right-[-300px]"} transition-all duration-500 top-0 w-[300px]`}>
                <i className="fa-solid fa-xmark text-[#10cc71] text-[30px] absolute top-[10px] cursor-pointer left-[10px]"
                   onClick={() => hideShowMobileNav()}
                ></i>
                <div className="logo-and-links relative flex flex-col justify-center">
                    <Link to="/" className="flex justify-center items-center">
                        <img loading="lazy" className="platform-logo w-[100px]" alt="logo" src={platformLogo ? `${process.env.REACT_APP_PUBLIC_API}/storage/${platformLogo}` : platfotmImg} />
                    </Link>
                    <ul className="flex flex-col">
                        <li className="relative text-center lg:mx-3 hover:translate-x-1 transition-all duration-500" style={{"--i" : "1.6s"}}>
                            <Link className="block py-2 text-white" to="/" onClick={()=>{
                                hideShowMobileNav()
                            }}>{t("Home")}</Link>
                        </li>
                        <li className="relative text-center lg:mx-3 hover:translate-x-1 transition-all duration-500" style={{"--i" : "1.3s"}}>
                            <Link className="block py-2 text-white" to="/about" onClick={()=>{
                                hideShowMobileNav()
                            }}>{t("About Us")}</Link>
                        </li>
                        <li className="relative text-center lg:mx-3 hover:translate-x-1 transition-all duration-500" style={{"--i" : "1s"}}>
                            <Link className="block py-2 text-white" to="/courses" onClick={()=>{
                                hideShowMobileNav()
                            }}>{t("Courses")}</Link>
                        </li>
                        <li className="relative text-center lg:mx-3 hover:translate-x-1 transition-all duration-500" style={{"--i" : "1s"}}>
                            <Link className="block py-2 text-white" to="/instructors" onClick={()=>{
                                hideShowMobileNav()
                            }}>{t("Instructors")}</Link>
                        </li>
                        <li className="relative text-center lg:mx-3 hover:translate-x-1 transition-all duration-500" style={{"--i" : "1s"}}>
                            <Link className="block py-2 text-white" to="/contact" onClick={()=>{
                                hideShowMobileNav()
                            }}>{t("Contact Us")}</Link>
                        </li>
                        <li className="relative text-center lg:mx-3 transition-all duration-500 categories-list-mobile-nav">
                            {/* <CategoriesDropDown categories={categories} closeNav={() => hideShowMobileNav()}/> */}
                            <CategoriesList  categories={categories} handleCloseNav={() => hideShowMobileNav()}/>
                        </li>
                    </ul>
                    <div className="relative flex flex-col">
                        <Link className="mb-1 text-center text-lightText" href={`mailto:${contactEmail}`}>
                            {contactEmail}
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MobileNavBar;
