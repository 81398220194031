import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../store/slices/userSlicer";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../images/academy-logo.png";
import SendEmailModal from "../../../component/global/global-modals/ForgotPassModal";
import { useForm } from "react-hook-form";
import "../styles/login-custome-style.css";
import { getProfileData } from "../../../store/slices/users/profileSlicer";
import loginBg from "../../../images/login-bg.jpg"
import ContextApi from "../../../store/slices/Context";
import { useTranslation } from "react-i18next";
import { GoogleLogin } from "@react-oauth/google";
import { LoginWithGoogleFunc } from "../../../store/slices/google-auth/LoginWithGoogleSlicer";
import { jwtDecode } from "jwt-decode";

export default function LoginFrom() {
  const {t} = useTranslation();
  const {data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
  const {loading:getUiSettingsLoading , data} = useSelector(
    state => state.getUiSettingsSlicer
  )
  const [showSendingEmailModal, setShowSendingEmailModal] = useState();
  const navigator = useNavigate();
  const {register , formState : {errors} , reset , handleSubmit} = useForm()
  const [showPass , setShowPass] = useState(false);
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.user);
  const profileApi = `${process.env.REACT_APP_PUBLIC_API}/api/profile`;
  const contextApi = useContext(ContextApi)
  const siteLogo = contextApi?.site_logo;
  const getProfile = async () => {
    try {
      dispatch(getProfileData(profileApi))
      .then(result => {
        if(result.payload && result.payload.invoices) {
          if (result.payload.invoices && result.payload.invoices.length >= 1) {
            const invoices = result.payload.invoices;
            window.localStorage.setItem("studentInvoices" , JSON.stringify(invoices))
          }
        }
      })
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitData = (data) => {
    dispatch(loginUser(data)).then(result =>{
      if(result.payload?.data) {
        if(result.payload.data.student) {
          if(!result.payload.data.student.email_verified_at) {
            navigator('/verify-email');
          }else {
            if (result.payload.success === true) {
              navigator('/');
              window.location.reload();
              reset();
            }
          }
        }
      }
    });
  };
  const onSuccess = (res) => {
    const decoded = jwtDecode(res.credential);
    console.log(decoded)
    const userCredintianalswithGoogle = {
      name: decoded.name,
      email: decoded.email,
      googleId: decoded.sub,
    };
    dispatch(LoginWithGoogleFunc(userCredintianalswithGoogle)).then(
      (result) => {
        if (result.payload.status_code === 200 && result.payload?.success === true) {
          setTimeout(() => window.location.reload() , 10)
          navigator("/");
          // getProfile();
        } else {
          console.log(result.payload.message);
        }
      }
    );
  };
  const onFailure = () => {
    console.log("Login Failed");
  };

  useEffect(() => process.env.REACT_APP_CLIENT_ID && console.log(process.env.REACT_APP_CLIENT_ID), [])
  return (
    <>
        <div className="page-content relative w-full h-[100vh] login-page"
            style={{
              // backgroundImage: `url(${loginBg})` 
            
            backgroundPosition: "center center" , backgroundRepeat: "no-repeat" , backgroundSize: "cover"}}
        >
            <div className="page-container row relative w-[90%] flex justify-center items-center h-full mx-auto py-4 ">
                <div className="form-div relative flex flex-col justify-center items-center w-full md:w-[400px] rounded-lg py-3 bg-[#f7f7f7] dark:bg-darkBg ">
                    <i className="fa-solid fa-arrow-left absolute top-[15px] left-[15px] text-blue-900 cursor-pointer transition-all duration-500 hover:translate-x-[-4px]"
                        onClick={() => navigator('/')}
                    ></i>
                    <Link to="/">
                        <img src={`${process.env.REACT_APP_PUBLIC_API}/storage/${siteLogo}`} alt="logo" className="w-[150px]" />
                    </Link>
                    <p className="text-sm dark:text-lightText">{t("You don't have an account?")} <Link to={"/register"} className="text-primary">{t("Create an account")}</Link></p>
                    <div className="register-form-content col-sm-12">
                            {
                            error 
                            && 
                            (
                              <p className="mb-1 login-error text-red-700 text-sm text-center bg-[#ef444485] border border-red-700 rounded-md py-2 px-3">{error}</p>
                            )
                            }
                            <form onSubmit={handleSubmit((data) => {
                              handleSubmitData(data);
                            })}>
                              <div className="form-groub relative mb-1 w-full">
                                <label className="mb-2 text-sm dark:text-lightText">{t("Email")}</label>
                                {errors.email?.message &&
                                <p className="text-red-600 text-sm mb-1">{errors.email?.message}</p>
                                }
                                <div className="relative flex flex-col col-sm-12">
                                    <input
                                        {...register("email" , {required : t("Email is required")})}
                                        className="w-full col-sm-12 indent-[1px] text-sm flex rounded-lg h-[50px] bg-transparent border placeholder:opacity-40 shadow-none dark:text-lightText"
                                        type="email"
                                    />
                                </div>
                              </div>
                              <div className="form-groub relative mb-1 w-full">
                                <label className="mb-2 text-sm dark:text-lightText">{t("Password")}</label>
                                {errors.password?.message &&
                                <p className="text-red-600 text-sm mb-1">{errors.password?.message}</p>
                                }
                                <div className="relative flex flex-col col-sm-12">
                                    <input
                                        {...register("password" , {required : t("Password is required")})}
                                        className="w-full col-sm-12 indent-[1px] text-sm flex h-[50px] bg-transparent border placeholder:opacity-40 shadow-none dark:text-lightText"
                                        type={`${showPass ? "text" : "password"}`}
                                    />
                                    {
                                      showPass
                                      ?
                                      <i className={`fa-solid fa-eye-slash cursor-pointer absolute top-[50%] translate-y-[-50%] dark:text-lightText ${languageData?.defaultLang?.slug === "ar" ? "left-[10px]" : "right-[10px]"} `}
                                      onClick={() => setShowPass(!showPass)}
                                      ></i>
                                      :
                                      <i className={`fa-solid fa-eye cursor-pointer absolute top-[50%] translate-y-[-50%] dark:text-lightText ${languageData?.defaultLang?.slug === "ar" ? "left-[10px]" : "right-[10px]"}`}
                                      onClick={() => setShowPass(!showPass)}
                                      ></i>
                                    }
                                </div>
                              </div>
                              <div className="relative mb-2">
                                <Link
                                  className="text-sm my-1 inline-block dark:text-lightText"
                                  onClick={() => setShowSendingEmailModal(true)}
                                >{t("Forgot your password?")}</Link>
                              </div>
                              <div className="tutor-form-row">
                                <div className="tutor-form-col-12">
                                  <div className="tutor-form-group tutor-reg-form-btn-wrap">
                                    <button
                                      type="submit"
                                      name="tutor_register_student_btn"
                                      className="btn col-sm-12 bg-primary text-white transition-all duration-500 hover:translate-y-[-3px] hover:bg-slate-800 mb-2"
                                    >
                                      {loading
                                        ? <>{t("loading")}...</>
                                        : 
                                        <>
                                        <i className="fa-solid fa-right-to-bracket transition-all duration-500 mr-1 rotate-[180deg] text-white"></i>
                                        {t("Login now")}
                                        </>
                                        }
                                    </button>
                                    {
                                      data?.enable_google_auth === "true"
                                      ?
                                      <>
                                        <div className="relative w-full flex justify-between items-center my-3">
                                            <span className="relative w-[30%] h-[1px] bg-slate-400 dark:bg-white"></span>
                                            <span className="dark:text-lightText text-center">
                                                {t("Sign In With")}
                                            </span>
                                            <span className="relative w-[30%] h-[1px] bg-slate-400 dark:bg-white"></span>
                                        </div>
                                        <div className="w-full flex justify-center items-center">
                                          <GoogleLogin
                                            onSuccess={onSuccess}
                                            onError={onFailure}
                                            theme="outline"
                                          />
                                        </div>
                                      </>
                                      :
                                      ""
                                    }
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                </div>
            </div>
        </div>
    <SendEmailModal
      show = {showSendingEmailModal}
      onHide = {() => setShowSendingEmailModal(false)}
    />
    </>
  )
}


