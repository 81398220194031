import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

// import pageHeaderImg from '../../images/backgrounds/page-header-bg.png' 

export default function PageHeader({ title  , headerImg , loadingPage}) {
  const [mounted , setMounted] = useState();
  const {t} = useTranslation();
  const {loading:getUiSettingsLoading , data} = useSelector(
    state => state.getUiSettingsSlicer
  )
  const [img , setImage] = useState(null)
  useEffect(() => {
    data && setImage(data?.site_banner_header);
  },[data])
  useEffect(() => {
    !loadingPage ? setTimeout(() => setMounted(true) , 1000) : setMounted(false)
  },[loadingPage])
  return (
    <>
      <section
        className="page-header clearfix"
        style={{
          backgroundImage: `url(${img ? `${process.env.REACT_APP_PUBLIC_API}/storage/${img}` : headerImg})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="page-header__wrapper clearfix">
                <div className="page-header__title flex justify-center items-center">
                  <h2 className={`transition-all duration-500 text-center ${mounted ? "translate-x-0 opacity-100" : "translate-x-5 opacity-0 text-center bg-slate-400 w-full"}`}>{t(title)}</h2>
                </div>
                <div className="page-header__menu">
                  <ul className="page-header__menu-list list-unstyled clearfix dark:bg-darkBg">
                    <li className={`transition-all duration-500 ${mounted ? "translate-y-0 opacity-100" : "translate-y-5 opacity-0"}`}>
                      <Link to={"/"} className="dark:text-lightText">{t("Home")}</Link>
                    </li>
                    <li className={`active text-primary transition-all duration-500 ${mounted ? "translate-y-0 opacity-100" : "translate-y-[-20px] opacity-0"}`}>{t(title)}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
