import MobileNavbar from "../../component/Nav/MobileNavbar";
import Navbar from "../../component/Nav/Navbar";
import Offcanvan from "../../component/Nav/Offcanvan";
import Btn from "../../component/ui/Btn";
import Footer from "../../component/ui/Footer";
import ScrollUp from "../../component/ui/ScrollUp";
import SearchBar from "../../component/ui/SearchBar";
import Wrappers from "../../component/ui/Wrappers";
import SingupForm from "./component/SingupForm";
import './styles/style.css'
export default function Singup() {
  return (
    <>
      <SingupForm />
    </>
  );
}
