import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getSlidersFunc = createAsyncThunk(
    'get sliders func',
    async (api)=> {
        try {
            const response = await axios.get(api);
            return response.data.data
        }
        catch(error) {
            console.log(error)
        }
    }
);


const getSlidersSlicer =  createSlice({
    name:'get sliders slicer',
    initialState :{
        loading:false,
        data:null,
        error:null
    },
    extraReducers: (builder)=>{
    builder.addCase(getSlidersFunc.pending,(state)=>{
        state.loading = true;
        state.data = null;
        state.error = null;
        
    });
    builder.addCase(getSlidersFunc.fulfilled,(state,action)=>{
        state.loading = false;
        state.data = action.payload;
        state.error = null;
    });
    builder.addCase(getSlidersFunc.rejected ,(state,action)=>{
        state.loading = false;
        state.data = null;
        state.error = action.error.message;
    })
    }

});


export default getSlidersSlicer.reducer;
