// import { Swiper, SwiperSlide } from "swiper/react";
// import {
//   Navigation,
//   Pagination,
//   Scrollbar,
//   A11y,
//   EffectFade,
//   Autoplay,
// } from "swiper/modules";
// // Import Swiper styles
// import "swiper/css/pagination";
// import CardCategories from "../../pages/Home/component/ui/CardCategories";
// import faildImg from "../../images/question-mark (1).png";
// import stImg from "../../images/graduated.png";
// import { useDispatch, useSelector } from "react-redux";
// import { getRankedStudentsFunc } from "../../store/slices/students-actions/GetRankedStudentsSlicer";
// import { useEffect, useState } from "react";
// import { Spinner } from "react-bootstrap";
// import { useTranslation } from "react-i18next";


// export default function Partners() {
//   const {t} = useTranslation();
//   const dispatch = useDispatch();
//   const [rankedList , setRankedList] = useState([]);
//   const {loading , data} = useSelector(state => state.getRankedStudentsSlicer)
//   const rankedApi = `${process.env.REACT_APP_PUBLIC_API}/api/students-highest-scores`;
//   let params = {
//     breakpoints: {
//         200 : {
//             slidesPerView : 1,
//         },
//         640: {
//             slidesPerView: 1,
//         },
//         768: {
//             slidesPerView: 2,
//         },
//         1024: {
//             slidesPerView: 3,
//         },
//     },
// }

//   const getRankedSt = () => {
//     dispatch(getRankedStudentsFunc(rankedApi))
//     .then(result => console.log(result))
//   }

//   useEffect(() => {
//     getRankedSt()
//   },[])
//   useEffect(() => {
//     data && data.rankedStudents &&  setRankedList(data.rankedStudents);
//   },[data])

//   return (
//     <>
//       <section className="company-logos-one">
//         <div className="container">
//           <div className="section-title text-center">
//             <span className="section-title__tagline text-[#00a4e5]">{t("اللوحة الشرفية")}</span>
//             <h2 className="section-title__title">{t("الطلبة المتميزين هذا الشهر")}</h2>
//           </div>
//           <Swiper
//             className="parent-of-slides"
//             {...params}
//             slidesPerView={3}
//             navigation
//             modules={[Pagination , Navigation]}
//             pagination = {{clickable : true}}
//             spaceBetween={80}
//             speed={1000}
//           >
          
//           {
//             loading
//             ?
//             <>
//               <div className="row d-flex justify-content-center gap-2">
//                 <Spinner animation="grow" variant="warning" />
//                 <Spinner animation="grow" variant="warning" />
//                 <Spinner animation="grow" variant="warning" />
//                 <Spinner animation="grow" variant="warning" />
//               </div>
//             </>
//             :
//             (
//               rankedList.length >= 1
//               ?
//               rankedList.map((student , index) => {
//                 return (
//                   <SwiperSlide
//                     key={index}
//                   >
//                     <CardCategories
//                       studentName={student.name}
//                       email={student.email}
//                       studentSay={student.email}
//                       studentRank={student.rank}
//                       studenImg={student.image_url !== null ? student.image_url : stImg}
//                       studentScore = {student.total_score}
//                     />
//                   </SwiperSlide>
//                 )
//               })
//               :
//               <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
//                 <img 
//                   loading="lazy"
//                   src={faildImg}
//                   alt="faild img"
//                   className="w-[150px] mb-3"
//                 />
//                 <p>
//                   {t("لا يوجد ترتيبات حتي الان")}!
//                 </p>
//               </div>
//             )
//           }
//           </Swiper>
//         </div>
//       </section>
//     </>
//   );
// }

import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFade,
  Autoplay,
} from "swiper/modules";
// Import Swiper styles
import "swiper/css/pagination";
import CardCategories from "../../pages/Home/component/ui/CardCategories";
import failedImg from "../../images/question-mark (1).png";
import studentImg from "../../images/graduated.png";
import { useDispatch, useSelector } from "react-redux";
import { getRankedStudentsFunc } from "../../store/slices/students-actions/GetRankedStudentsSlicer";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ContextApi from "../../store/slices/Context";

export default function Partners() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [rankedList, setRankedList] = useState([]);
  const { loading, data } = useSelector(state => state.getRankedStudentsSlicer);
  const rankedApi = `${process.env.REACT_APP_PUBLIC_API}/api/students-highest-scores`;
  const contextApi = useContext(ContextApi);
  const showingTopStudends = contextApi?.page_top_students_show
  let params = {
    breakpoints: {
      200: {
        slidesPerView: 1,
      },
      640: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      1024: {
        slidesPerView: 3,
      },
    },
  };

  const getRankedSt = () => {
    dispatch(getRankedStudentsFunc(rankedApi))
  };

  useEffect(() => {
    getRankedSt();
  }, []);

  useEffect(() => {
    data && data.rankedStudents && setRankedList(data.rankedStudents);
  }, [data]);
  return (
    showingTopStudends !== "false"
    ?
    <>
      <section className="company-logos-one py-[100px]">
        <div className="container-fluid">
          <div className="section-title text-center">
            {/* <span className="section-title__tagline text-[#00a4e5]">{t("Honor Roll")}</span> */}
            <h2 className="section-title__title">{t("Top Students of the Month")}</h2>
          </div>
          <Swiper
            className="parent-of-slides aos"
            data-aos="fade-up"
            {...params}
            slidesPerView={3}
            navigation
            modules={[Pagination, Navigation]}
            pagination={{ clickable: true }}
            spaceBetween={80}
            speed={1000}
          >
            {
              loading
                ?
                <>
                  <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                  </div>
                </>
                :
                (
                  rankedList.length >= 1
                    ?
                    rankedList.map((student, index) => {
                      return (
                        <SwiperSlide
                          key={index}
                        >
                          <CardCategories
                            studentName={student.name}
                            email={student.email}
                            studentSay={student.email}
                            studentRank={student.rank}
                            studenImg={student.image_url !== null ? student.image_url : studentImg}
                            studentScore={student.total_score}
                          />
                        </SwiperSlide>
                      );
                    })
                    :
                    <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                      <img
                        loading="lazy"
                        src={failedImg}
                        alt="failed img"
                        className="w-[150px] mb-3"
                      />
                      <p className="dark:text-lightText">
                        {t("No rankings available yet")}!
                      </p>
                    </div>
                )
            }
          </Swiper>
        </div>
      </section>
    </>
    :
    ""
  );
}
