import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

export const LoginWithGoogleFunc = createAsyncThunk(
  "login/googleAccount",

  async (userCredintianals) => {
    const request = await fetch(
      `${process.env.REACT_APP_PUBLIC_API}/api/auth/google`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: userCredintianals.name,
          email: userCredintianals.email,
          google_id: userCredintianals.googleId,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.success === true) {
          localStorage.setItem("userData", JSON.stringify(result?.data?.student));
          const token = result?.data?.token;
          const decodeToken = jwtDecode(token);
          window.localStorage.setItem("token" , token);
          window.localStorage.setItem("expire_date", decodeToken.exp);
        }
        return result;
      })
      .catch((error) => error);
    const response = request;
    return response;
  }
);

const loginWithGoogleSlicer = createSlice({
  name: "userDataWithGoogle",
  initialState: {
    loading: false,
    user: null,
    error: null,
  },

  reducers: {
    Logout(state) {
      state.loading = false;
      state.user = null;
      state.error = null;
      localStorage.removeItem("userData");
    },
  },

  extraReducers: (builder) => {
    builder.addCase(LoginWithGoogleFunc.pending, (state) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    });
    builder.addCase(LoginWithGoogleFunc.fulfilled, (state, action) => {
      if (action.payload.status_code === 200) {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      } else {
        state.loading = false;
        state.user = null;
        state.error = action.payload.message;
      }
    });
    builder.addCase(LoginWithGoogleFunc.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload.message;
    });
  },
});

export default loginWithGoogleSlicer.reducer;
export const { Logout } = loginWithGoogleSlicer.actions;
