// // Import Swiper React components
// import { Swiper, SwiperSlide } from "swiper/react";
// import {
//   Pagination,
//   Autoplay,
// } from "swiper/modules";
// import "swiper/css/pagination";
// import '../styles/swiper-style.css'
// import Btn from "../../../component/ui/Btn";
// import { useContext } from "react";
// import ContextApi from "../../../store/slices/Context";
// import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
// import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
// import bookIcon from "../../../images/bshape_03.png"
// import squareIcon from "../../../images/bshape_05.png"
// import mobileImg from "../../../images/online-education-home-schooling-elearning-banner-mobile-app-with-books-bachelor-cap_168129-2577-remo.webp"

// export default function SwiperSlider() {
//   const {t} = useTranslation();
//   const contextApi = useContext(ContextApi);
//   const slidersList = contextApi && contextApi.sliderImages;
//   const contactPhone = contextApi?.contact_phone;
//   return (
//     <>
//     <section className="main-slider main-slider-two">
//       <Swiper
//         modules={[ Pagination, Autoplay]}
//         // effect="fade"
//         slidesPerView={1}
//         loop={true}
//         speed={1000}
//         pagination={{ clickable: true }}
//         autoplay={{ delay: 5000 }}
//       >
//         {
//           // أبدأ رحلتك للتعلم
//           // تعرف علي كل ما هو جديد في منصة LMS التعليمية
//           slidersList
//           &&
//           slidersList.map((slider , index) => {
//             return (
//               <SwiperSlide key={index}>
//                 {/* <div className="h-[100vh - 70px]"> */}
//                 <div className="h-[100vh]  flex justify-center items-center">
//                   <div
//                     className={`image-layer bg-center bg-no-repeat bg-cover`}
//                     // style={{
//                     //   backgroundImage: `url(${slider.image_url})`
                      
//                     // }}
//                   />
//                   {/* <div className="image-layer-overlay opacity-70" /> */}
//                   <div className="container rounded-3xl p-3 bg-slate-400 flex justify-center items-center">
//                     <div className="row">
//                       <div className="relative col-sm-12 flex md:hidden justify-center items-center">
//                           <img src={mobileImg} alt="background img" className="max-w-[300px]" />
//                       </div>
//                       <div className="col-sm-12 col-md-6 flex flex-col justify-center">
//                         {/* <div className="relative rounded-md p-2 border border-primary w-fit text-sm text-stone-50 start-learning-head mb-[30px] bg-[#3c9f9fb0]">
//                           {t("Start Your Learning Journy Now")} !
//                         </div> */}
//                         <div className="main-slider-two__content text-center md:text-start">
//                           <h2 className="main-slider-two__tagline font-bold text-slate-800 sm:text-[30px] md:text-[40px]">
//                             {t(slider.title)}
//                           </h2>
//                           <div 
//                           className="main-slider__title sm:text-[20px] md:text-[40px]" 
//                           dangerouslySetInnerHTML={{__html : t(slider.description)}}  />
//                         </div>
//                         <div className="main-slider-two__button-box flex justify-center md:justify-start items-center">
//                           <Btn title={t('Explore More')} link={slider.link}/>
//                           <div className="relative flex items-center mx-4">
//                             <div className="relative flex flex-col">
//                               <p>{t("Have Any Question ?")}</p>
//                               <Link to={`https://wa.me/${contactPhone}`} target="_blank">
//                                 {contactPhone}
//                               </Link>
//                             </div>
//                             <PhoneInTalkIcon className="text-primary mx-1 text-[50px]" /> 
//                           </div>
//                         </div>
//                       </div>
//                       <div className="col-sm-12 col-md-6 hidden md:inline-flex">
//                         <div className="image-div relative">
//                           <img src={slider.image_url} alt="slider img" className="relative" />
//                           <img src={bookIcon} alt="slider img" className="absolute top-[20px] left-0 max-w-[100px]" />
//                           <img src={squareIcon} alt="slider img" className="absolute top-0 right-0 max-w-[100px]" />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </SwiperSlide>
//             )
//           }
//           )
//         }
//       </Swiper>
//       </section>
//     </>
//   );
// }
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Pagination,
  Autoplay,
} from "swiper/modules";
import "swiper/css/pagination";
import '../styles/swiper-style.css'
import Btn from "../../../component/ui/Btn";
import { useContext } from "react";
import ContextApi from "../../../store/slices/Context";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import bookIcon from "../../../images/bshape_03.png"
import squareIcon from "../../../images/bshape_05.png"
import mobileImg from "../../../images/online-education-home-schooling-elearning-banner-mobile-app-with-books-bachelor-cap_168129-2577-remo.webp"

export default function SwiperSlider() {
  const {t} = useTranslation();
  const contextApi = useContext(ContextApi);
  const slidersList = contextApi && contextApi.sliderImages;
  const contactPhone = contextApi?.contact_phone;
  return (
    <>
      <section className="main-slider main-slider-two">
          <Swiper
            modules={[ Pagination, Autoplay]}
            slidesPerView={1}
            loop={true}
            speed={1000}
            pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            // spaceBetween={50}
          >
            {
              slidersList
              &&
              slidersList.map((slider , index) => {
                return (
                  <SwiperSlide key={index} className="flex justify-center items-start">
                    <div className="h-[100vh] container-fluid m-0 flex justify-center ">
                      <div className="container-fluid  md:after:hidden relative my-0 rounded-3xl overflow-hidden p-3  min-h-[80vh]">
                        <div
                          className={`image-layer bg-center bg-no-repeat bg-cover z-[9]`}
                          style={{
                            backgroundImage: `url(${slider.image_url})`
                          }}
                        />
                        <div className="row flex items-center absolute w-full py-3 z-[99]  top-[50%] translate-y-0 md:translate-y-[-50%]">
                          <div className="relative col-sm-12 col-md-6 px-[20px] md:px-[50px] flex flex-col justify-center">
                            <div className="main-slider-two__content text-center md:text-start">
                              <h2 className="main-slider-two__tagline mb-3 font-bold text-white text-[25px] md:text-[40px]">
                                {t(slider.title)}
                              </h2>
                              <div 
                              className="main-slider__title m-0 text-[17px] font-normal text-white md:text-[23px]" 
                              dangerouslySetInnerHTML={{__html : t(slider.description)}}  />
                            </div>
                            {
                              slider?.link
                              ?
                              <div className="main-slider-two__button-box flex justify-center md:justify-start items-center">
                                <Btn classes={"rounded-xl text-[14px]"} title={t('Explore More')} link={slider.link}/>
                              </div>
                              :
                              ""
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                )
              }
              )
            }
          </Swiper>
      </section>
    </>
  );
}
